import { useEffect, useRef } from 'react';

export function WooshEffect() {
    const wooshContainerRef = useRef<HTMLDivElement>(null);
    const intervalId = useRef<number | null>(null);

    useEffect(() => {
        function startWoosh() {
            intervalId.current = setInterval(() => {
                if (!wooshContainerRef.current) return;

                const woosh = document.createElement('div');
                woosh.innerHTML = `
                    <div class="absolute translate-y-0 transition-transform duration-[1000ms]">
                        <div class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"></div>
                    </div>
                `;
                const wooshElement = woosh.firstElementChild as HTMLDivElement;
                wooshContainerRef.current.appendChild(wooshElement);

                setTimeout(() => animateWoosh(wooshElement), 10);
            }, 150);
        }

        function animateWoosh(el: HTMLDivElement) {
            let random = (max: number) => Math.floor(Math.random() * max);
            let parent = el;
            let child = el.firstElementChild as HTMLDivElement;

            parent.style.left = random(100) + '%';
            parent.style.top = random(1000) - 500 + 'px';
            parent.classList.remove('translate-y-0');
            setTimeout(() => parent.classList.add('translate-y-[500px]'), 50);

            child.classList.remove('opacity-0');
            setTimeout(() => {
                child.classList.add('opacity-0');
                setTimeout(() => {
                    el.remove();
                }, 1000);
            }, 650);
        }

        startWoosh();

        return () => {
            if (intervalId.current) clearInterval(intervalId.current);
        };
    }, []);

    return (
        <div
            ref={wooshContainerRef}
            className="pointer-events-none absolute inset-0 h-[2400px] w-full overflow-hidden pb-64"
        >
            {/* Initial wooshes are omitted as these were just illustrative and initial render content is not needed */}
        </div>
    );
}
